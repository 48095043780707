<mat-nav-list>
  <a
    *ngFor="let item of data"
    (routerLink)="(item.route)"
    mat-list-item
    (click)="openLink($event)"
  >
    <span mat-line>{{ item.title }}</span>
  </a>
</mat-nav-list>
