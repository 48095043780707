import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private api: ApiService) {}

  public async changePassword(data: any): Promise<any> {
    return await this.api
      .put('panel/accounts/admin/password', data)
      .toPromise();
  }
}
