import { ReportModule } from './modules/report/report.module';
import { AppLayoutComponent } from './layout/app-layout.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CoreModule } from './core/core.module';
import { AuthModule } from './modules/auth/auth.module';
import {
  LoaderComponent,
  ProgressBarLoaderComponent,
  MobileNavComponent,
  ChangePasswordComponent,
} from '@shared/components';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    LoaderComponent,
    ProgressBarLoaderComponent,
    MobileNavComponent,
    ChangePasswordComponent,
    AppLayoutComponent,
  ],
  imports: [
    MatProgressBarModule,
    BrowserAnimationsModule,
    BrowserModule,
    AuthModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
