<div class="background">
  <div class="container">
    <mat-card class="card">
      <mat-card-title>
        <div class="logo-container">
          <img
            src="assets/frilah_logo_black.png"
            class="login-img"
            alt="logo"
          />
        </div>
      </mat-card-title>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="login()">
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Usuário</mat-label>
                <input
                  required
                  matInput
                  formControlName="username"
                  name="username"
                />
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Senha</mat-label>
                <input
                  required
                  matInput
                  formControlName="password"
                  type="password"
                  name="password"
                />
              </mat-form-field>
            </div>
            <div class="col-12 my-3">
              <button
                type="submit"
                mat-raised-button
                color="primary"
                class="full-width"
              >
                Entrar
              </button>
            </div>

            <div class="col">
              <a
                mat-button
                color="primary"
                class="mb-3"
                [disabled]="!form.value['username']"
                (click)="form.value['username'] && recoveryPassword()"
              >
                Recuperar senha
              </a>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
