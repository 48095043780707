import { Pipe, PipeTransform, Injectable } from '@angular/core';

import { WithdrawRequestStatusEnum } from '@app/enums';
@Injectable({ providedIn: 'root' })
@Pipe({ name: 'withdrawRequestStatus' })
export class WithdrawRequestStatusPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    switch (value) {
      case WithdrawRequestStatusEnum.ACCEPTED:
        return 'Aceito';
      case WithdrawRequestStatusEnum.PENDENT:
        return 'Pendente';
      case WithdrawRequestStatusEnum.REFUSED:
        return 'Recusado';
      default:
        return '-';
    }
  }
}
