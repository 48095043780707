<mat-toolbar class="app-toolbar">
  <button
    class="nav-btn"
    mat-icon-button
    matTooltip="Menu"
    (click)="openBottomSheet()"
    *ngIf="screenWidth < 1000"
  >
    <mat-icon style="color: #2574a9; margin-right: 8px">menu</mat-icon>
  </button>
  <img
    src="/assets/frilah_logo_black.png"
    width="160vw"
    alt="logo"
    class="swing"
  />
  <span class="spacer"></span>
  <div *ngIf="screenWidth >= 1000">
    <span *ngFor="let item; let i = index; of: navMenuItems">
      <button
        class="nav-btn mr-1"
        mat-flat-button
        *ngIf="!item.children"
        routerLink="/{{ item.route }}"
        [class]="pageRouter === '/' + item.route ? 'active-router' : ''"
      >
        {{ item.title }}
      </button>

      <button
        class="nav-btn mr-1"
        mat-flat-button
        [matMenuTriggerFor]="subItemsMenu"
        *ngIf="item.children"
        [class]="pageRouter === '/' + item.route ? 'active-router' : ''"
      >
        {{ item.title }}
      </button>
      <mat-menu #subItemsMenu="matMenu">
        <button
          mat-menu-item
          [matTooltip]="subItem.title"
          *ngFor="let subItem of item.children"
          routerLink="/{{ subItem.route }}"
        >
          {{ subItem.title }}
        </button>
      </mat-menu>
    </span>
  </div>

  <div class="profile-wrap" [matMenuTriggerFor]="menu">
    <img class="avatar-img" [src]="avatarUser" />
    <span>{{
      username.length > 15 ? username.substring(0, 15) + "..." : username
    }}</span>
  </div>
  <mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item (click)="changePassword()">Alterar Senha</button>
    <button mat-menu-item (click)="logout()">Sair</button>
  </mat-menu>
</mat-toolbar>

<router-outlet></router-outlet>
