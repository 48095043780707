<h2 mat-dialog-title>Alterar Senha</h2>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Senha atual</mat-label>
          <input required matInput formControlName="password" type="password" />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Nova Senha</mat-label>
          <input
            required
            matInput
            formControlName="newPassword"
            type="password"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">Fechar</button>
  <button mat-button [disabled]="form.invalid" (click)="save()">Salvar</button>
</mat-dialog-actions>
