import { AuthService } from './../core/service/auth.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import {
  ChangePasswordComponent,
  MobileNavComponent,
} from '@shared/components';

export interface NavMenuItems {
  title: string;
  route?: string;
  children?: Array<NavMenuItems>;
}

@Component({
  selector: 'app-layout-component',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit {
  public username: any = '';

  public navMenuItems: NavMenuItems[] = [
    {
      title: 'Dashboard',
      route: 'dashboard',
    },
    {
      title: 'Profissões',
      children: [
        {
          title: 'Todas Profissões',
          route: 'profissoes',
        },
        {
          title: 'Solicitações para ingressar em uma Profissão',
          route: 'profissoes/solicitacoes',
        },
        {
          title: 'Sugestões de profissão',
          route: 'profissoes/sugestoes',
        },
        {
          title: 'Categorias',
          route: 'profissoes/categorias',
        },
      ],
    },
    {
      title: 'Pedidos',
      children: [
        {
          title: 'Serviços',
          route: 'pedidos/servicos',
        },
        {
          title: 'Marketplace',
          route: 'pedidos/marketplace',
        },
      ],
    },
    {
      title: 'Carteira',
      children: [
        {
          title: 'Carteira Principal',
          route: 'carteira',
        },
        {
          title: 'Requisições de saque',
          route: 'carteira/requisicoes-saque',
        },
      ],
    },
    {
      title: 'Usuários',
      route: 'usuarios',
    },
    {
      title: 'Planos',
      route: 'planos',
    },
    {
      title: 'Permissões',
      route: 'permissoes',
    },
    {
      title: 'Denúncias',
      route: 'denuncias',
    },
  ];

  public pageRouter = '';
  public screenWidth = window.innerWidth;
  isAdmin = false;
  public clearance = '20';

  constructor(
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private readonly authService: AuthService,
    public dialog: MatDialog
  ) {}

  get avatarUser(): string {
    return `https://ui-avatars.com/api/?name=${this.username.toUpperCase()}&background=random`;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
  }

  openBottomSheet(): void {
    this.bottomSheet.open(MobileNavComponent, { data: this.navMenuItems });
  }

  ngOnInit(): void {
    this.onResize();
    this.authService.currentUser.subscribe({
      next: (user) => {
        this.username = user?.name ?? '';
      },
    });

    this.isAdmin = localStorage.getItem('accountType') === 'admin';
    this.clearance = localStorage.getItem('clearance') || '20';

    this.pageRouter = this.router.routerState.snapshot.url;
    if (this.pageRouter === '/') {
      this.router.navigate(['dashboard']);
    }

    this.router.events.subscribe({
      next: () => {
        this.pageRouter = this.router.routerState.snapshot.url;
      },
    });
  }

  changePassword(): void {
    this.dialog
      .open(ChangePasswordComponent, {
        disableClose: true,
        minWidth: '50%',
      })
      .afterClosed();
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
