import { WalletOperationEnum } from '@app/enums';
import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'walletOperation' })
export class WalletOperationPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case WalletOperationEnum.ADD:
        return 'Entrada';
      case WalletOperationEnum.REFUND:
        return 'Estorno';
      case WalletOperationEnum.WITHDRAW:
        return 'Retirada';

      default:
        return '-';
    }
  }
}
