import { ApprovalStatusPipe } from './pipes/ApprovalStatus.pipe';
import {
  CurrencyMaskModule,
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig,
} from 'ng2-currency-mask';
import { PercentageValuePipe } from './pipes/PercentageValue.pipe';
import { WithdrawRequestStatusPipe } from './pipes/WithdrawRequestStatus.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { MaterialModule } from './material.module';
import {
  BooleanValuePipe,
  OrderMarketplaceStatusPipe,
  OrdersStatusPipe,
  PaymentMethodPipe,
  PaymentStatusPipe,
  UserStatusPipe,
  WalletOperationPipe,
} from './pipes';
import { ReportTypePipe } from './pipes/ReportType.pipe';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    CurrencyMaskModule,
  ],
  declarations: [
    BooleanValuePipe,
    OrderMarketplaceStatusPipe,
    OrdersStatusPipe,
    PaymentMethodPipe,
    PaymentStatusPipe,
    UserStatusPipe,
    WalletOperationPipe,
    WithdrawRequestStatusPipe,
    PercentageValuePipe,
    ApprovalStatusPipe,
    ReportTypePipe,
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule,
    MaterialModule,
    BooleanValuePipe,
    OrderMarketplaceStatusPipe,
    OrdersStatusPipe,
    PaymentMethodPipe,
    PaymentStatusPipe,
    UserStatusPipe,
    WalletOperationPipe,
    WithdrawRequestStatusPipe,
    PercentageValuePipe,
    CurrencyMaskModule,
    ApprovalStatusPipe,
    ReportTypePipe,
  ],
})
export class SharedModule {}
