import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { PaymentStatusEnum } from '@app/enums';
@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'paymentStatus' })
export class PaymentStatusPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    switch (value) {
      case PaymentStatusEnum.HALF:
        return 'Pago 1ª metade';
      case PaymentStatusEnum.PAID:
        return 'Pago';
      case PaymentStatusEnum.PENDENT:
        return 'Pendente';
      case PaymentStatusEnum.REFUND:
        return 'Estornado';

      default:
        return '-';
    }
  }
}
