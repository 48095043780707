import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { OrderStatusEnum } from '@app/enums';
@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'orderStatus' })
export class OrdersStatusPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    switch (value) {
      case OrderStatusEnum.ACCEPTED:
        return 'Aceito';
      case OrderStatusEnum.ACTIVE:
        return 'Ativo';
      case OrderStatusEnum.CANCELED:
        return 'Cancelado';
      case OrderStatusEnum.FINISHED:
        return 'Finalizado';
      case OrderStatusEnum.NEGOTIATION:
        return 'Negociação';
      case OrderStatusEnum.PENDENT:
        return 'Pendente';
      case OrderStatusEnum.REFUNDED:
        return 'Estornado';

      default:
        return '-';
    }
  }
}
