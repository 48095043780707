import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { UserStatusEnum } from '@app/enums';

@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'userStatus' })
export class UserStatusPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    switch (value) {
      case UserStatusEnum.ACTIVE:
        return 'Ativo';
      case UserStatusEnum.DELETED:
        return 'Excluído';
      case UserStatusEnum.INCOMPLETE:
        return 'Incompleto';
      case UserStatusEnum.SUSPENDED:
        return 'Suspenso';
      case UserStatusEnum.PENDENT:
        return 'Pendente';

      default:
        return '-';
    }
  }
}
