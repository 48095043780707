import { ValidationErrors, AbstractControl } from '@angular/forms';

export function matchValues(
  matchTo: string // name of the control to match to
): (AbstractControl: any) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    return !!control.parent &&
      !!control.parent.value &&
      // @ts-ignore
      control.value === control.parent.controls[matchTo].value
      ? null
      : { isMatching: false };
  };
}

export function minLengthArray(min: number): ValidationErrors | null {
  return (c: AbstractControl): ValidationErrors | null => {
    if (c.value.length >= min) {
      return null;
    }

    return { minLengthArray: { valid: false } };
  };
}
