import { ApprovalStatusEnum } from './../../core/enums/approval-status-enum';
import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'approvalStatus' })
export class ApprovalStatusPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    switch (value) {
      case ApprovalStatusEnum.wait:
        return 'Aguardando avaliação';
      case ApprovalStatusEnum.approv:
        return 'Aprovado';
      case ApprovalStatusEnum.done:
        return 'Aprovado automaticamente';
      case ApprovalStatusEnum.denied:
        return 'Negado';
      default:
        return '-';
    }
  }
}
