import { Pipe, PipeTransform } from '@angular/core';
import { OrderMarketplaceStatusEnum } from '@app/enums';

@Pipe({ name: 'orderMarketplaceStatus' })
export class OrderMarketplaceStatusPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    switch (value) {
      case OrderMarketplaceStatusEnum.ACCEPTED:
        return 'Aceito';
      case OrderMarketplaceStatusEnum.CANCELED:
        return 'Cancelado';
      case OrderMarketplaceStatusEnum.FINISHED:
        return 'Finalizado';
      case OrderMarketplaceStatusEnum.PENDENT:
        return 'Pendente';
      case OrderMarketplaceStatusEnum.REFUNDED:
        return 'Estornado';
      default:
        return '-';
    }
  }
}
