import { ToastrService } from 'ngx-toastr';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '@app/service/settings.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private service: SettingsService,
    private snackBar: MatSnackBar,
    private readonly toastrService: ToastrService,
    private router: Router
  ) {
    this.form = formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(4)]],
      newPassword: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  ngOnInit(): void {}

  async save(): Promise<void> {
    const values = this.form.getRawValue();
    try {
      await this.service.changePassword(values);
      this.toastrService
        .success('Senha alterada com sucesso')
        .onHidden.subscribe({
          next: () => {
            localStorage.clear();
            this.router.navigate(['/login']);
          },
        });
    } catch (response) {
      this.toastrService.error(
        response?.error?.message ?? 'Erro ao ao alterar senha.'
      );
    }
  }
}
