import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/service/auth.service';
import { LoaderService } from '@app/service/loading.service';
import { environment } from '@env';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly loaderService: LoaderService,
    private readonly toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.showMessageActivateSuccess();
    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  showMessageActivateSuccess(): void {
    const isRedirectActivate =
      this.route.snapshot.queryParams.isRedirectActivate === 'true';
    if (isRedirectActivate) {
      this.router.navigate(['auth/login']);
      this.toastrService.success('Usuário ativado com sucesso!');
    }
  }

  login(): void {
    this.loaderService.showGlobal();

    this.authService
      .login(this.form.value.username, this.form.value.password)
      .subscribe(
        (res: any) => {
          localStorage.setItem(environment.tokenStorageKey, res.access_token);
          this.authService.currentUser.next(res.user);
          this.toastrService.success('Login realizado com sucesso!');
          this.router.navigate(['/dashboard']);
        },
        () =>
          this.toastrService.error(
            'Erro ao realizar login, verifique suas credenciais!'
          )
      )
      .add(() => this.loaderService.hideGlobal());
  }

  async recoveryPassword(): Promise<void> {
    this.authService
      .recoveryPassword(this.form.value.username, 'admin')
      .subscribe({
        next: () => {
          this.toastrService.success(
            'E-mail para recuperação de senha enviado com sucesso!'
          );
        },
        error: (err) => {
          this.toastrService.error(
            err?.error?.message ?? 'Erro ao realizar requisição.'
          );
        },
      });
  }
}
