/**
 * descrição do status de pagamento. \
 * pendent = ainda não foi pago nada. \
 * half = metade do trabalho já está pago. \
 * paid = o trabalho foi totalmente pago. \
 * refunded = o valor pago pelo trabalho foi estornado
 */
export enum PaymentStatusEnum {
  PENDENT = 'pendent',
  HALF = 'half',
  PAID = 'paid',
  REFUND = 'refunded',
}
