import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@app/service/loading.service';

@Component({
  selector: 'app-progress-bar-loader',
  templateUrl: './progress-bar-loader.component.html',
  styleUrls: ['./progress-bar-loader.component.scss'],
})
export class ProgressBarLoaderComponent implements OnInit {
  isLoading = false;
  globalLoading = false;

  constructor(readonly loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.globalLoading.subscribe((v) => {
      this.globalLoading = v;
    });
    this.loaderService.progressLoading.subscribe((v) => {
      this.isLoading = v;
    });
  }
}
