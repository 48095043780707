export enum EnumCompanyStatus {
  WAITING = 'waiting',
  PENDENT = 'pendent',
  ACTIVE = 'active',
  ACCEPTED = 'accepted',
  IDLE = 'idle',
  FINISHED = 'finished',
  DELETED = 'deleted',
  CANCELED = 'canceled',
  AVAILABLE = 'available',
  BUSY = 'busy',
  OFFLINE = 'offline',
  SUSPENDED = 'suspended',
}
