import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/service/auth.service';
import { User } from './../../../../core/entities/user';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { matchValues } from '@app/validators/matchValue';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss'],
})
export class RecoveryPasswordComponent implements OnInit {
  token: string;
  form: FormGroup;
  user: User;

  constructor(
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly toastrService: ToastrService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token;
    this.checkToken();
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: [
        '',
        [Validators.required, matchValues('password')],
      ],
    });
  }

  checkToken() {
    this.authService.checkTokenRecoveryPassword(this.token).subscribe({
      next: (user) => {
        this.user = user;
      },
      error: () => {
        this.toastrService.error('Token inválido');
        this.router.navigate(['/']);
      },
    });
  }

  save() {
    const { password } = this.form.value;
    this.authService
      .resetPassword(password, this.token, this.user.id)
      .subscribe({
        next: () => {
          this.toastrService.success('Senha redefinida com sucesso!');
          this.router.navigate(['/']);
        },
        error: (response) => {
          this.toastrService.error(
            response?.error?.message ?? 'Erro ao resetar senha'
          );
        },
      });
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get('password')?.value;
    const confirmPass = group.get('confirmPass')?.value;

    return pass === confirmPass ? null : { notSame: true };
  }
}
