import { ReportTypeEnum } from './../../core/enums/report-type-enum';
import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'reportType' })
export class ReportTypePipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    switch (value) {
      case ReportTypeEnum.COMMENT:
        return 'Comentário';
      case ReportTypeEnum.IMAGE:
        return 'Imagem';
      case ReportTypeEnum.POST:
        return 'Publicação';
      case ReportTypeEnum.PROFILE:
        return 'Perfil';

      default:
        return '-';
    }
  }
}
