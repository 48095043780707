/**
 * Estados do pedido: \
 * pendent: Pedido recém criado. \
 * negotiation: Profissional estipulou um valor e enviou para o cliente aceitar. \
 * accepted: Cliente aceitou pagar o valor negociado. \
 * active: O Profissional iniciou o serviço. \
 * finished: O Profissional concluiu o serviço. \
 * canceled: Pedido cancelado por uma das partes ou pelo sistema. \
 * refunded: Pedido estornado.
 */
export enum OrderStatusEnum {
  PENDENT = 'pendent',
  NEGOTIATION = 'negotiation',
  ACCEPTED = 'accepted',
  ACTIVE = 'active',
  FINISHED = 'finished',
  CANCELED = 'canceled',
  REFUNDED = 'refunded',
}
