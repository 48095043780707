import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public globalLoading = new BehaviorSubject<boolean>(false);
  public progressLoading = new BehaviorSubject<boolean>(false);

  public showGlobal(): void {
    this.globalLoading.next(true);
  }

  public hideGlobal(): void {
    this.globalLoading.next(false);
  }

  public showProgress(): void {
    this.progressLoading.next(true);
  }

  public hideProgress(): void {
    this.progressLoading.next(false);
  }
}
