import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { PaymentMethodEnum } from '@app/enums';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'paymentMethod' })
export class PaymentMethodPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    switch (value) {
      case PaymentMethodEnum.CIELO:
        return 'Cielo';
      case PaymentMethodEnum.PICPAY:
        return 'Picpay';

      default:
        return '-';
    }
  }
}
