<div class="container">
  <mat-card class="card">
    <mat-card-title>
      <img src="assets/frilah_logo_black.png" alt="StorageCar Logo" />
      <p class="my-3">Recuperação de senha</p>
    </mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="save()">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Senha</mat-label>
              <input
                required
                matInput
                formControlName="password"
                type="password"
                name="password"
              />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Confirmação Senha</mat-label>
              <input
                required
                matInput
                formControlName="passwordConfirmation"
                type="password"
                name="passwordConfirmation"
              />
            </mat-form-field>
          </div>

          <div class="col-12">
            <button
              type="submit"
              mat-raised-button
              [disabled]="form.invalid"
              color="primary"
              class="full-width"
            >
              Alterar senha
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
