import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/entities/user';
import { environment } from '@env';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null as any);

  constructor(
    public router: Router,
    private readonly apiService: ApiService,
    private readonly toastrService: ToastrService
  ) {}

  login(username: string, password: string): Observable<User> {
    return this.apiService.post<User>('auth/admin', {
      username,
      password,
    });
  }

  getAccessToken(): string | null {
    return localStorage.getItem(environment.tokenStorageKey);
  }

  get isLoggedIn(): boolean {
    const authToken = this.getAccessToken();
    this.refreshToken();
    return authToken !== null ? true : false;
  }

  logout(): void {
    localStorage.removeItem(environment.tokenStorageKey);
    this.router.navigate(['auth/login']);
  }

  recoveryPassword(
    email: string,
    accountType: 'user' | 'admin'
  ): Observable<void> {
    return this.apiService.post<void>(`auth/password/reset/create`, {
      email,
      accountType,
    });
  }

  resetPassword(
    password: string,
    token: string,
    id: number,
    accountType = 'user'
  ): Observable<void> {
    return this.apiService.put('auth/password/reset', {
      password,
      token,
      id,
      accountType,
    });
  }

  checkTokenRecoveryPassword(token: string): Observable<User> {
    return this.apiService.get<User>(`auth/password/reset/${token}`);
  }

  refreshToken(): void {
    this.apiService.get('auth/token/refresh').subscribe({
      next: (res: any) => {
        this.currentUser.next(res.user);
        localStorage.setItem(environment.tokenStorageKey, res.access_token);
      },
      error: (response: any) => {
        this.toastrService.error(
          response?.error?.message ?? 'Erro ao recuperar sessão.'
        );
      },
    });
  }
}
